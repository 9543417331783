import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, notification, Space } from 'antd';
import { useMutation } from '@apollo/client';
import useMessage from 'antd/es/message/useMessage';
import EntitiesSearchActions from './EntitiesSearchActions';
import EntitiesSearchDrawer from './drawer/EntitiesSearchDrawer';
import EntitiesSearchContext from '../EntitiesSearchContext';
import { CriteriaInputFactory } from '../../CriteriaInput/CriteriaInputFactory';
import Icon from '../../Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import SimpleSearchOverview from './drawer/overview/SimpleSearchOverview';
import { useLocalization } from '../../../util/useLocalization';
import EntitiesSearchTitleAndCountries, { isCountrySearchSupportedForEntity } from './EntitiesSearchTitleAndCountries';
import Puffin from '../../Puffin/Puffin';
import { UpdateDefaultCriteriasMutationMutation,
  UpdateDefaultCriteriasMutationMutationVariables } from '../../../../gql/typings';
import { UPDATE_MUTATION } from '../../../browse/admin/adminComponents/AdminConfigureSearch/AdminConfigureSearch';
import { DEFAULT_CRITERIAS_DATA_QUERY } from '../index';

const puffinNotificationKey = 'puffin-global-search-notification';

const EntitiesSearchTop = ({
  children = <></>,
}) => {
  const {
    entityType,
    selectedCriterias,
    setSelectedCriterias,
    doSearch,
    form,
    setSelectedSaved,
    tableSelection,
    disabledCriteriaIds,
    selectedCountriesState: [selectedCountries],
    globalState,
  } = useContext(EntitiesSearchContext);
  const [drawerState, setDrawerState] = useState<'hidden' | 'search-drawer' | 'save-search-drawer'>('hidden');
  const localization = useLocalization();
  const [messageApi, contextHolderMessage] = useMessage();
  const onSaveSearch = () => {
    setSelectedSaved(null);
    setDrawerState('save-search-drawer');
  };

  const [
    updateDefaultSearch,
    { loading: isUpdating },
  ] = useMutation<UpdateDefaultCriteriasMutationMutation, UpdateDefaultCriteriasMutationMutationVariables>(UPDATE_MUTATION);


  const [api, contextHolder] = notification.useNotification();


  const isGlobal = globalState && globalState[0];

  const doSaveSearchAsDefault = () => {
    const criteriaIds = selectedCriterias.map(criteria => criteria.id);
    updateDefaultSearch(
      {
        refetchQueries: [DEFAULT_CRITERIAS_DATA_QUERY],
        variables: {
          entityType,
          criteriaIds
        }
      }
    ).then(() => messageApi.success(localization.formatMessage(Locale.Text.Search_saved_as_default)));
  };

  const removeNonSupportedGlobalSearchCriterias = () => {
    setSelectedCriterias(selectedCriterias.filter(c => c.hasGlobalSupport));
    api.destroy(puffinNotificationKey);
  };

  const openNotification = () => {
    api.open({
      key: puffinNotificationKey,
      message: `${localization.formatMessage(Locale.General.Attention)}!`,
      duration: null,
      description: <Space direction="vertical">
        <Puffin
          loop
          type="explaining"
          message={localization.formatMessage(Locale.Text.Local_criterias_added_to_global_search)}
        />
        <Space align="center" style={{ width: '100%', justifyContent: 'center' }}>
          <Button onClick={() => api.destroy(puffinNotificationKey)}>
            {localization.formatMessage(Locale.Command.Cancel)}
          </Button>
          <Button type="primary" onClick={removeNonSupportedGlobalSearchCriterias}>
            {localization.formatMessage(Locale.Command.Remove)}
          </Button>
        </Space>
      </Space>,
    });
  };

  useEffect(() => {
    if (isGlobal) form.validateFields().catch(() => openNotification());
    else api.destroy(puffinNotificationKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isGlobal]);


  return (
    <div className="entities-search-top-container">
      {contextHolder}
      {contextHolderMessage}
      <EntitiesSearchTitleAndCountries />
      <Form layout="inline" size="large" form={form} onFinish={() => doSearch('EntitiesSearchTop:Form:onFinish')}>
        {(selectedCriterias ?? []).map(criteria => {
          let disabled: boolean | string = false;
          let tooltipError: undefined | string;
          if (disabledCriteriaIds?.includes(criteria.id)) disabled = true;
          if (globalState && globalState[0] && !criteria.hasGlobalSupport) {
            tooltipError = localization.formatMessage(Locale.Text.This_criteria_not_supported_in_global_search);
          }

          return (
            <CriteriaInputFactory
              key={criteria.id}
              form={form}
              criteria={criteria}
              countryCodes={isCountrySearchSupportedForEntity(entityType) ? selectedCountries : undefined}
              options={{
                disabled,
                tooltipError,
              }}
              initialValueOptionsLoad
            />
          );
        })}
        <Form.Item className="criteria-plus">
          <Space>
            <Button className="search-option-btn" onClick={() => setDrawerState('search-drawer')}>
              {localization.formatMessage(Locale.Command.Add_field)}
            </Button>
            <Button className="search-option-btn" loading={isUpdating} onClick={doSaveSearchAsDefault}>
              {localization.formatMessage(Locale.Command.Save_as_default)}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                tableSelection.tableProps.pageState[1](1);
                doSearch('EntitiesSearchTop:Button:onClick');
              }}
              id="entities-search-button"
            >
              <Icon
                iconType="SEARCH"
                style={{ height: 14, marginRight: 5 }}
                text={localization.formatMessage(Locale.Command.Search)}
              />
            </Button>

          </Space>

        </Form.Item>
        <Button htmlType="submit" style={{ display: 'none' }} />
        <EntitiesSearchDrawer
          drawerState={[
            drawerState === 'search-drawer' || drawerState === 'save-search-drawer',
            v => setDrawerState(v ? 'search-drawer' : 'hidden'),
          ]}
        >
          {drawerState === 'save-search-drawer' && <SimpleSearchOverview
            drawerState={[true, v => setDrawerState(v ? 'search-drawer' : 'hidden')]}
          />}
        </EntitiesSearchDrawer>
      </Form>
      <EntitiesSearchActions onSaveSearch={onSaveSearch}>
        {children}
      </EntitiesSearchActions>
    </div>
  );
};

export default EntitiesSearchTop;
