import React, { useEffect, useState } from 'react';
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import { isArray, isUndefined } from 'lodash';
import { useThrottleFn } from 'ahooks';
import { Select } from 'antd';
import { Optional } from '../../util/StateArrayType';
import { usePersonFieldMutation } from '../../browse/person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../browse/search_old/types';
import { useLocalization } from '../../util/useLocalization';
import TransferComponent, { RecordTypeSpecialtiesDataItem } from '../TransferModal/TransferComponent';
import { Locale } from '../../../localization/LocalizationKeys';

interface RecordType {
  id: number;
  controlSetting?: Optional<{ id: number; createDcr: boolean }>;
}

type SuggestionValue = {
  code: string;
  label: string;
};

type SingleSelectUpdateFieldArgs<T, V> = {
  mode: 'single';
  allowClear: boolean;
  selectPlaceholder: string;
  selectedKey: (record: T) => undefined|string;
  buildVariables: (selectedKey: string|undefined, record: T) => V;
  fetchSuggestions: (args: {
    client: ApolloClient<object>;
    userInput: Optional<string>;
    record: T;
  }) => Promise<SuggestionValue[]>;
};

type MultipleSelectUpdateFieldArgs<T, V> = {
  mode: 'multiple';
  modalHeading: string;
  selectedKeys: (record: T) => undefined|string[];
  buildVariables: (selectedKeys: string[], record: T) => V;
  fetchSuggestions: (args: {
    client: ApolloClient<object>;
    record: T;
  }) => Promise<SuggestionValue[]>;
};

type SelectUpdateFieldArgs<T extends RecordType, V> = (
  SingleSelectUpdateFieldArgs<T, V>|MultipleSelectUpdateFieldArgs<T, V>
) & {
  mutation: DocumentNode;
  skipDcrWarning?: boolean;
};

/**
 * When using the update config with 'mode': 'multiple'. Remember to set the [preserve] to [true] in the field config options.
 */
export function buildSelectUpdateFieldConfig<T extends RecordType, V>(
  args: SelectUpdateFieldArgs<T, V>,
) {
  return (props: TableFieldUpdateViewProps<T>) => {
    const localization = useLocalization();
    const apollo = useApolloClient();
    const [suggestions, setSuggestions] = useState<SuggestionValue[]>();
    const {
      Renderer,
      blocking,
      submit,
    } = usePersonFieldMutation({
      mutation: args.mutation,
      controlSetting: props.record.controlSetting,
      asModal: args.mode === 'multiple',
      skipDcrWarning: args.skipDcrWarning,
    });
    const { run: fetchSuggestions } = useThrottleFn(args.fetchSuggestions, { wait: 350, });

    const doFetch = (userInput: Optional<string>) => {
      fetchSuggestions({
        client: apollo,
        record: props.record,
        userInput,
      })?.then(setSuggestions);
    };

    useEffect(() => {
      doFetch('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apollo]);

    const availableValueItems: RecordTypeSpecialtiesDataItem[] = (suggestions ?? []).map(s => ({
      key: s.code,
      title: s.label,
    }));
    const k = args.mode === 'single'
      ? args.selectedKey(props.record)
      : args.selectedKeys(props.record);
    const selectedState = useState<string[]>(isArray(k) ? k : [k].filter(e => e) as string[]);

    return (
      <Renderer
        heading={args.mode === 'multiple' && args.modalHeading
          ? args.modalHeading
          : localization.formatMessage(Locale.Command.Select)}
        onClose={props.endEditing}
        width="clamp(320px, 75%, 900px)"
        variables={args.mode === 'single'
          ? args.buildVariables(selectedState[0][0], props.record)
          : args.buildVariables(selectedState[0], props.record)}
      >
        {args.mode === 'multiple'
          ? <TransferComponent dataSource={availableValueItems} selectedKeys={selectedState} />
          : (
            <Select
              placeholder={args.selectPlaceholder}
              loading={isUndefined(suggestions)}
              showSearch
              autoFocus
              allowClear={args.allowClear}
              onBlur={() => !blocking && props.endEditing()}
              onSearch={doFetch}
              value={selectedState[0][0]}
              filterOption={false}
              style={{ width: 'clamp(120px, 100%, 230px)' }}
              onChange={selectedKey => submit(
                args.buildVariables(selectedKey, props.record)
              ).then(() => !blocking && props.endEditing())}
            >
              {(suggestions ?? []).map(suggestion => (
                <Select.Option key={suggestion.code} value={suggestion.code}>
                  {suggestion.label}
                </Select.Option>
              ))}
            </Select>
          )}
      </Renderer>
    );
  };
}
